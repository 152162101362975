import React from 'react'

class ContactButtons extends React.Component  {
  render() {
    return <p className="mt-10 mb-8">
      <a href="mailto:info@sektornauten.de" className="btn mr-3 toggle-text-on-hover">
        <span>
          <span>Mail schreiben</span>
          <span>info@sektornauten.de</span>
        </span>
      </a>
      <a href="tel:+493080619380" className="btn toggle-text-on-hover">
        <span>
          <span>Jetzt anrufen</span>
          <span>030 - 80 61 93 80</span>
        </span>
      </a>
    </p>
  }
}
export default ContactButtons;
