import React from 'react'
import ZieleImage from '../images/ziele-fuer-nachhaltige-entwicklung.svg'

class Ziele extends React.Component  {
  render() {
    return <div>
      <img src={ZieleImage} className="w-56 mt-5 mb-6" alt="17 Ziele für nachhaltige Entwicklung" />
      <p className="mb-16">
        Wir sind überzeugt von den „17 Zielen für nachhaltige Entwicklung“ der United Nations. Deshalb übernehmen wir diese Ziele für unser Handeln. Mehr Informationen unter <a className="underline" href="https://www.17ziele.de">www.17ziele.de</a>.
      </p>
    </div>
  }
}

export default Ziele;
