import React from 'react'

class Reference extends React.Component  {
  render() {
    return  <div className="reference">
      <img src={this.props.image} alt={this.props.client} />
      <div className="reference-details">
        <p>{this.props.text}</p>
        <p className="mb-0">{this.props.client}</p>
      </div>
    </div>
  }
}

export default Reference;
