import React from 'react'
import contactBerlin from '../images/contact-berlin.png'
import contactBonn from '../images/contact-bonn.png'
import office1 from '../images/office-1.jpg'
import office2 from '../images/office-2.jpg'
import office3 from '../images/office-3.jpg'
import office4 from '../images/office-4.jpg'
import Ziele from './Ziele'
import ContactButtons from './ContactButtons'

export class Contact extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {
    return (
      <div className="content-wrapper">
        <h2 id="kontakt">Ein Team, zwei Standorte.</h2>

        <div className="md:grid md:grid-cols-2 md:gap-8 mb-16">
          <div className="mb-3 text-white p-5 bg-cover bg-right-bottom text-xl" style={{ height: '25rem', backgroundImage: "url(" + contactBerlin + ")" }}>
            Büro Berlin<br />
            Sewanstraße 122<br />
            10319 Berlin
          </div>
          <div className="mb-3 text-white p-5 bg-cover bg-right-bottom text-xl" style={{ height: '25rem', backgroundImage: "url(" + contactBonn + ")" }}>
            Büro Bonn<br />
            Dorotheenstraße 43<br />
            53111 Bonn
          </div>
        </div>

        <h2>Neues Projekt?<br />Wir sind Ihr Team.</h2>

        <ContactButtons />

        <h2>Unsere Mission</h2>
        <p className="mb-16">
          Gemeinsam gute, nachhaltige und individuelle Produkte für den Nutzer im Web zu kreieren.
        </p>
        <div className="grid md:grid-cols-2 gap-4 mb-32">
          <img src={ office1 } alt="Situation im Office" />
          <img src={ office2 } alt="Situation im Office" />
          <img src={ office3 } alt="Situation im Office" />
          <img src={ office4 } alt="Situation im Office" />
        </div>

        <Ziele />

      </div>
    )
  }
}
