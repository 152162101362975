import React from 'react'
import {Route, Link, Router, Switch} from 'react-router-dom'
import {createHashHistory} from "history"
import logoBlue from './images/logo-blue.svg'
import {Page} from './components/Page'
import {Impressum} from './components/Impressum'
import {Privacy} from './components/Privacy'
import {Contact} from './components/Contact'
import {Menu} from './components/Menu'
import {MenuLink} from './components/MenuLink'
import {Footer} from './components/Footer'

export const history = createHashHistory()

function App() {
  return (
    <React.StrictMode>
      <Router basename="/" history={history}>
        {/* <div className="text-blue subpixel-antialiased md:pt-3 mx-auto">
          <div className="container" style={{ minHeight: 'calc(100vh - 11rem)' }}>
            <div className="flex flex-col md:flex-row items-end md:justify-between mb-16 mt-6">
              <Link to="/">
                <img className="w-full md:w-auto md:h-24 mb-6 md:mb-0 mx-auto md:ml-0" src={logoBlue} alt="Logo Sektornauten" />
              </Link>
              <Switch>
                <Route exact path='/menu' render={(props) => <MenuLink underline={true} page="/" />} />
                <Route exact path='*' render={(props) => <MenuLink underline={false} page="/menu" />} />
              </Switch>
            </div>



            <Route exact path='/menu' component={Menu} />
            <Route exact path='/' component={Page} />
            <Route exact path='/impressum' component={Impressum} />
            <Route exact path='/datenschutz' component={Privacy} />
            <Route exact path='/kontakt' component={Contact} />
          </div>
          <Switch>
            <Route exact path='/menu'>
            </Route>
            <Route exact path='*'>
              <Footer />
            </Route>
          </Switch>
        </div> */}
      </Router>
    </React.StrictMode>
  );
}

export default App;
