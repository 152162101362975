import React from 'react'
import {Link} from 'react-router-dom'
import logoWhite from '../images/logo-white.svg'

export class Footer extends React.Component {

  render() {
    return (
      <footer className="bg-blue mt-20 py-12">
        <div className="container  flex justify-between items-center">
          <img className="h-12 md:h-24 md:mx-auto md:ml-0" src={logoWhite} alt="Logo Sektornauten" />
          <nav>
            <ul className="">
              <li><Link className="text-white md:text-xl font-bold underline" to="/kontakt">Kontakt</Link></li>
              <li><Link className="text-white md:text-xl font-bold underline" to="/impressum">Impressum</Link></li>
              <li><Link className="text-white md:text-xl font-bold underline" to="/datenschutz">Datenschutz</Link></li>
            </ul>
          </nav>
        </div>
      </footer>
    )
  }
}
