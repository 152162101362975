import React from 'react'
import {Link} from 'react-router-dom'
import { HashLink } from "react-router-hash-link"

export class Menu extends React.Component {

  componentDidMount() {
    document.body.classList.add("bg-green")
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-green")
  }

  render() {
    return (
      <div className="container">
        <div className="content-wrapper flex items-center justify-center">
          <ul className="text-center">
            <li><HashLink to='/#unsere-mission' className="mb-8 inline-block font-bold leading-none text-4xl cursor-pointer hover:underline">Über uns</HashLink></li>
            <li><HashLink to="/#technologien" className="mb-8 inline-block font-bold leading-none text-4xl cursor-pointer hover:underline">Technologien & Methoden</HashLink></li>
            <li><Link to='/kontakt' className="mb-8 inline-block font-bold leading-none text-4xl cursor-pointer hover:underline">Kontakt</Link></li>
            <li><Link to='/impressum' className="mb-8 inline-block font-bold leading-none text-4xl cursor-pointer hover:underline">Impressum</Link></li>
            <li><Link to='/datenschutz' className="mb-8 inline-block font-bold leading-none text-4xl cursor-pointer hover:underline">Datenschutz</Link></li>
          </ul>
        </div>
      </div>
    )
  }
}
